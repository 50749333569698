<template>
  <v-card color="background" height="100%" style="border-radius: 0px; position: relative;">
    <!--
        <div class="search-bar" v-if="searchOpen" >
            <input type="text" placeholder="Pesquisar" @blur="toggleSearch" class="search-bar-border">
        
            <v-btn text color="#2b4093" v-bind="attrs" v-on="on" class="search-button">
                Pesquisar
            </v-btn>
        </div>
        -->

    <div class="limiter-content">
      <v-row no-gutters>
        <v-col class="px-3 px-lg-0">
          <div class="social-icons limiter-content ">
            <v-btn v-if="!user" text @click="dialogAccess = true" color="primary">
              <v-icon class="mr-2" color="primary">mdi-lock</v-icon>
              Cadastre-se / Login
            </v-btn>

            <v-menu v-else min-width="200" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on">
                  <v-icon class="mr-2">mdi-account</v-icon>
                  {{ user ? 'Olá, ' + normalizeName(user.name) : ' Cadastre-se / Login' }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item link :to="null" color="primary" @click.native="logout">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Sair</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <v-btn text @click="dialogAccess = true" color="primary">
                            <v-icon class="mr-2">mdi-account</v-icon>
                            {{ 'Olá, ' + normalizeName(user.name) }}
                        </v-btn> -->

            <!--          
<v-icon color="primary" style="margin-right: 20px;" @click="toggleSearch">mdi-magnify</v-icon>
-->

            <a href="https://www.facebook.com/brizolaejapur/" target="_blank">
              <v-icon color="primary">mdi-facebook</v-icon>
            </a>
            <a href="https://instagram.com/brizolajapur?utm_medium=copy_link" target="_blank">
              <v-icon color="primary">mdi-instagram</v-icon>
            </a>

            <a href="https://www.linkedin.com/company/brizola-e-japur" target="_blank">
              <v-icon color="primary">mdi-linkedin</v-icon>
            </a>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <!-- <v-spacer></v-spacer> -->
        <v-col>
          <div class="d-flex align-center px-3 px-lg-0">
            <v-row justify="space-between" align="center">
              <v-col>
                <img src="@/assets/logo-brizola-japur-small.png" width="50px" />
              </v-col>

              <div class="px-4 hidden-lg-and-up">
                <v-col class="mx-2">
                  <v-btn icon color="black" @click="toogleMenuApp">
                    <v-icon size="40">mdi-menu</v-icon>
                    <span class="font-weight-bold ml-4">MENU</span>
                  </v-btn>
                </v-col>
              </div>
            </v-row>

            <div></div>

            <v-row justify="space-between" class="hidden-md-and-down">
              <div v-for="(item, index) in sidebarMenu" :key="index">
                <template v-if="!item.children || item.children.length === 0">
                  <v-btn text :to="item.route" class="menu-item">
                    {{ item.name }}
                  </v-btn>
                </template>
              </div>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text color="#2c4093" v-bind="attrs" v-on="on" class="ml-2 menu-buttons">
                    <span class="v-btn__content">
                      CASOS / EMPRESAS
                      <span class="v-btn__content--inside">
                        <v-icon right>mdi-chevron-down</v-icon>
                      </span>
                    </span>
                  </v-btn>
                </template>
                <v-list class="dropdown-menu-list">
                  <v-list-item v-for="(item, index) in dropDownCasesCompanies" :key="index" :to="item.route" link
                    class="submenu-item">
                    <v-btn text :to="item.route" class="text-white-on-hover">
                      {{ item.name }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text color="#2c4093" v-bind="attrs" v-on="on" class="menu-buttons" style="margin-left: 10px;">
                    <span class="v-btn__content">
                      ÁREA DO CREDOR
                      <span class="v-btn__content--inside">
                        <v-icon right>mdi-chevron-down</v-icon>
                      </span>
                    </span>
                  </v-btn>
                </template>

                <v-list class="dropdown-menu-list">
                  <v-list-item v-for="(item, index) in dropDownCreditorArea" :key="index" :to="item.route" link
                    class="submenu-item">
                    <v-btn text :to="item.route" class="text-white-on-hover">
                      {{ item.name }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>


            </v-row>

            <!--
    <v-btn text color="#2b4093" class="menu-buttons" style="margin-left: 10px;" to="/assembleia-credores">
        ASSEMBLEIA DE CREDORES
    </v-btn>                

    
    <v-btn text color="#2b4093" class="menu-buttons" style="margin-left: 10px;" to="/solicitacao-ppp">
        SOLICITAÇÃO DE PPP
    </v-btn>
    -->

            <v-menu v-if="user" min-width="200" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <!-- <div class="logged-user d-none d-sm-flex ml-4">
                <span class="logged-user-name font-weight-bold">{{ user.name }}</span>
                <span class="logged-user-email">{{ user.email }}</span>
            </div> -->
                <!-- 
                                <v-btn text v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2">mdi-account</v-icon>
                                    {{ user ? 'Olá, ' + normalizeName(user.name) : ' Cadastre-se / Login' }}
                                </v-btn> -->
              </template>
              <v-list>
                <v-list-item link :to="null" color="primary" @click.native="logout">
                  <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Sair</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
      </v-row>

      <!-- MENU MOBILE -->
      <v-row class="hidden-lg-and-up" v-if="menuApp" @clickaway="closeMenuApp">
        <v-col cols="12" justify="space-between" align="end">
          <v-col cols="12">
            <div class="py-2" v-for="(item, index) in sidebarMenu" :key="index">
              <template v-if="!item.children || item.children.length === 0">
                <v-btn @click="toogleMenuApp" text :to="item.route" class="menu-item">
                  {{ item.name }}
                </v-btn>
              </template>
            </div>
          </v-col>

          <v-col cols="12" class="py-2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="#2c4093" v-bind="attrs" v-on="on" class="menu-buttons" style="margin-left: 10px;">
                  <span class="v-btn__content">
                    AREA DO CREDOR
                    <span class="v-btn__content--inside">
                      <v-icon right>mdi-chevron-down</v-icon>
                    </span>
                  </span>
                </v-btn>
              </template>

              <v-list class="dropdown-menu-list">
                <v-list-item v-for="(item, index) in dropDownCreditorArea" :key="index" :to="item.route" link
                  class="submenu-item">
                  <v-btn @click="toogleMenuApp" text :to="item.route" class="text-white-on-hover">
                    {{ item.name }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>

          <v-col cols="12" class=" py-2">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text color="#2c4093" v-bind="attrs" v-on="on" class="ml-2 menu-buttons">
                  <span class="v-btn__content">
                    CASOS / EMPRESAS
                    <span class="v-btn__content--inside">
                      <v-icon right>mdi-chevron-down</v-icon>
                    </span>
                  </span>
                </v-btn>
              </template>
              <v-list class="dropdown-menu-list">
                <v-list-item v-for="(item, index) in dropDownCasesCompanies" :key="index" :to="item.route" link
                  class="submenu-item">
                  <v-btn @click="toogleMenuApp" text :to="item.route" class="text-white-on-hover">
                    {{ item.name }}
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-col>
      </v-row>
    </div>

    <!-- <v-app-bar app dark color="transparent" clipped-left flat style="position: relative;">
            <v-app-bar-nav-icon color="primary" @click="drawer = true" class="d-flex d-md-none"></v-app-bar-nav-icon> -->

    <v-spacer></v-spacer>

    <!-- </v-app-bar> -->

    <v-navigation-drawer v-model="drawer" absolute temporary color="primary">
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="user" class="mt-10">
            <v-list-item-content>
              <v-list-item-title><span class="white--text"><v-icon class="mr-2 white--text">mdi-account</v-icon>
                  {{ 'Olá, ' + normalizeName(user.name) }}</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-for="(item, index) in sidebarMenu" :key="index" link :to="item.route" color="white">
            <v-list-item-content>
              <v-list-item-title><span class="white--text">{{ item.name }}</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!user" class="mt-10" @click="dialogAccess = true">
            <v-list-item-content>
              <v-list-item-title><span class="white--text">
                  <v-icon class="mr-2 white--text">mdi-account</v-icon> Cadastre-se / Login</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else class="mt-10" @click="logout">
            <v-list-item-content>
              <v-list-item-title><span class="white--text">
                  <v-icon class="mr-2 white--text">mdi-logout</v-icon> Sair</span></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-dialog persistent v-model="dialogRegister" :width="600">
      <register-component></register-component>
    </v-dialog>

    <v-dialog v-model="dialogForgotPassword" :width="600">
      <forgot-password-component></forgot-password-component>
    </v-dialog>

    <v-dialog v-model="dialogAccess" :width="600">
      <access-component></access-component>
    </v-dialog>

    <progress-bar></progress-bar>

    <div v-if="breadcrumbItems.length" class="lightViolet mt-5">
      <v-breadcrumbs class="limiter-content" :items="breadcrumbItems"></v-breadcrumbs>
    </div>

    <v-container v-if="!verifyIgnoredRouter" class="limiter-content">
      <router-view></router-view>
    </v-container>

    <template v-else>
      <router-view></router-view>
    </template>
  </v-card>
</template>

<script>
// import userApi from "@/common/api/user.api";
import { eventHub } from '@/main';

import router from '../router';

import ProgressBar from '@/components/layout/ProgressBar.vue';
import Register from '@/views/user/Register.vue';
import Access from '@/views/user/Access.vue';
import ForgotPassword from "@/views/user/ForgotPassword.vue";

import sidebarMenu from '@/common/data/sidebar-menu.data';
import breadcrumbs from '@/common/data/breadcrumbs.data';
import dropDownCreditorArea from '@/common/data/creditor-area.data';
import dropDownCasesCompanies from '@/common/data/cases-companies.data';

export default {
  name: 'PrivateLayout',

  components: {
    'progress-bar': ProgressBar,
    'register-component': Register,
    'access-component': Access,
    'forgot-password-component': ForgotPassword
  },

  data() {
    return {
      user: null,
      logoUrl: null,
      menuGroups: [],
      drawer: false,
      menuApp: false,
      dialogRegister: false,
      dialogAccess: false,
      dialogForgotPassword: false,
      searchTerm: null,
      displaySettingsMenu: false,
      sidebarMenu: sidebarMenu,
      dropDownCreditorArea: dropDownCreditorArea,
      dropDownCasesCompanies: dropDownCasesCompanies,
      searchOpen: false,
      breadcrumbs: breadcrumbs,
      breadcrumbItems: [],
      defaultBreadcrumb: { text: 'Brizola e Japur', href: '/#/home' },
      currentRoute: null,
      ignoreStyleRoutes: ['team', 'home']
    };
  },

  created() {
    this.user = this.$root.user;

    eventHub.$on('DIALOG-REGISTER', value => {
      this.dialogRegister = value;
    });

    eventHub.$on("DIALOG-FORGOT-PASSWORD", (value) => {
      this.dialogForgotPassword = value;
    });

    eventHub.$on('DIALOG-ACCESS', value => {
      this.dialogAccess = value;
    });

    this.$ga.enable();

    eventHub.$on('logout', () => {
      this.logout();
    });

    this.setActionButtons();

    this.normalizeBreadcrumb(this.$route);
  },

  destroyed() {
    eventHub.$off('THEME_UPDATED');
  },

  watch: {
    $route(to, from) {
      this.normalizeBreadcrumb(to);
    }
  },

  methods: {
    normalizeBreadcrumb(route) {
      const findedBreadcrumb = this.breadcrumbs.find(
        x => x.key.toLowerCase() === route.name.toLowerCase()
      );

      if (!findedBreadcrumb) {
        this.breadcrumbItems = [];
        return;
      }

      this.breadcrumbItems = [this.defaultBreadcrumb, ...findedBreadcrumb.breadcrumb];
    },

    setActionButtons() {
      // Grupo
    },
    //toggleSearch() {
    //    this.searchOpen = !this.searchOpen;
    //},

    // closeMenu() {
    //     this.$refs.menu.save()
    // },

    menuActionClick(event) {
      event.preventDefault();
    },

    normalizeName(name) {
      const splitName = name.split(' ');
      return splitName.length > 1 ? splitName[0] : name;
    },

    verifyIgnoredRouter() {
      const findedBreadcrumb = this.ignoreStyleRoutes.find(
        x => x.toLowerCase() === this.$route.name.toLowerCase()
      );

      return !!findedBreadcrumb;
    },

    async logout() {
      // if (await this.$root.$confirm(true, "user.logout")) {
      //     userApi.clearcurrentUser;
      //     this.$router.push({ name: "Login" });
      // }

      localStorage.clear();
      router.go('/home');
    },

    toogleMenuApp() {
      this.menuApp = !this.menuApp;
    },

    closeMenuApp() {
      this.meuApp = false;
    }
  }
};
</script>

<style scoped lang="scss">
.title {
  // color: var(--v-primary-base);
  color: #2b4093 !important;
  text-decoration: none;
}

.menu-bar {
  color: #2b4093 !important;
}

.text-button {
  font-size: 10px;
}

/*
.menu-button-container {
   // background-color: var(--v-secondary-base) !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80px;
    text-align: center;
}

.menu-button {
    margin: auto;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
*/

.logged-user {
  text-align: left;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 400;
  flex-direction: column;

  &-name {
    display: block;
  }

  &-email {
    display: block;
  }
}

.menu-item-default {
  color: #2b4093;
}

.menu-item-hover {
  color: #009986;
}

/*
.menu-subheader {
    padding-left: 16px;
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.001px;
}

.menu-title {
    padding-left: 16px;
    color: var(--v-important-base);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
*/
/*
.casos-submenu-item {
    color: white !important; 
    background-color: #2b4093 !important; 
    padding-left: 16px;
    border-bottom: 1px solid #009986;
    font-family: Raleway,sans-serif !important;
    font-size: 12px !important;
    font-style: normal;
    line-height: 24px;
    transition: color 0.3s; 
}
.casos-submenu-item:hover {
    color: green !important; 
}
*/

.menu-item {
  color: #2b4093 !important;
  // padding-left: 20px;
  // padding-right: 20px;
  // width: 100% !important;
  background-color: transparent !important;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  // line-height: 24px;
  transition: color 0.3s;
}

.menu-item:hover {
  color: var(--v-secondary-base) !important;
}

.submenu-item {
  color: white !important;
  background-color: #2b4093 !important;
  // padding-left: 16px;
  border-bottom: 1px solid #009986;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 24px;
  transition: color 0.3s;
}

.submenu-item:hover {
  color: var(--v-secondary-base) !important;
}

.menu-buttons {
  color: white !important;
  background-color: #2b4093 !important;
  border-bottom: 1px solid #009986;
  font-family: 'Raleway', sans-serif;
  font-size: 12px;
  font-style: normal;
  transition: color 0.3s;
  border-radius: 10px;
}

.menu-buttons:hover {
  color: var(--v-secondary-base) !important;
  background-color: white !important;
}

.text-white-on-hover {
  color: white !important;
}

.text-white-on-hover:hover {
  color: var(--v-secondary-base) !important;
}

.hover-green:hover {
  color: green;
}

.social-icons {
  position: relative;
  margin-right: auto;
  margin-right: -24px !important;
  padding-top: 20px;
  // margin-right: 200px;
  // display: flex;
  text-align: right;
  background: transparent;
  // right: 100px;
}

.v-breadcrumbs {
  padding-left: 0 !important;
}

.social-icons a {
  margin-right: 20px;
  text-decoration: none;
  color: inherit;
}

.dropdown-menu-list {
  // background-color: var(--v-primary-base) !important;
  padding: 0px;
}
</style>
